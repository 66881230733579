<button
  [ngClass]="styles"
  [title]="title"
  (click)="downloadPdf(id)"
  mat-icon-button
  aria-label="PDF icon"
  [ngStyle]="{ 'background-color': '#0d1a2b' }"
>
  <mat-icon svgIcon="file-pdf-outline"></mat-icon>
</button>
