<div class="flex min-h-full bg-gray-50">
  <div class="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:px-20 xl:px-24">
    <div class="w-full max-w-sm mx-auto lg:w-96">
      <div class="flex justify-center">
        <img class="w-44 h-10" src="assets/full-Logo.svg" alt="Osavus" />
      </div>

      <div class="p-8 mt-10 bg-white rounded-md shadow">
        <div class="flex flex-col gap-3">
          <span class="text-2xl font-semibold leading-9 tracking-tight text-osavus-content-primary">
            Welcome back
          </span>
          <div class="flex gap-3">
            <div (click)="getAccessToken()"
              class="relative w-full justify-center cursor-pointer inline-flex items-center gap-x-1.5 rounded-lg bg-white px-3 py-3 text-sm font-semibold text-osavus-primary-90 stroke-gray-200 border border-gray-200 hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
              <img class="-ml-0.5 h-5 w-5" src="assets/google.svg" alt="Google" />
              <span i18n>Google</span>
            </div>
          </div>

          <div class="relative">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-200"></div>
            </div>
            <div class="relative flex justify-center text-sm font-medium leading-6">
              <span i18n class="px-6 text-osavus-content-primary bg-white">or</span>
            </div>
          </div>

          <form [formGroup]="loginForm" ngNativeValidate class="space-y-5">
            <div>
              <label for="username"
                class="block text-sm font-semibold leading-6 text-osavus-content-primary">Email</label>
              <div class="mt-2">
                <input id="username" name="username" type="email" autocomplete="username" formControlName="username"
                  placeholder="yourname@domain.com" required
                  class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 placeholder:font-semibold focus:ring-2 focus:ring-inset focus:ring-insert sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div>
              <label for="password"
                class="block text-sm font-semibold leading-6 text-osavus-content-primary">Password</label>
              <div class="mt-2">
                <input id="password" name="password" type="password" autocomplete="current-password"
                  formControlName="password" placeholder="" minlength="8" required
                  class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 placeholder:font-semibold focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <!-- <input id="remember-me" name="remember-me" type="checkbox"
                  class="w-4 h-4 cursor-pointer border-gray-300 rounded bg-gray-50 text-osavus-blue-80 focus:ring-blue-600" />
                <label for="remember-me"
                  class="block cursor-pointer ml-2 text-sm font-semibold leading-6 text-osavus-content-primary">Remember
                  me</label> -->
              </div>

              <div class="text-sm leading-6">
                <a routerLink="/login-portal/forgot-password"
                  class="font-semibold text-sm cursor-pointer text-osavus-blue-50 hover:text-blue-500">Forgot
                  password?</a>
              </div>
            </div>

            <div>
              <button (click)="onSubmit()" [disabled]="isSubmitting" [ngClass]="
                  isSubmitting
                    ? 'cursor-not-allowed bg-gray-400 text-gray-800 hover:bg-gray-400'
                    : 'cursor-pointer bg-osavus-blue-50 text-white'
                "
                class="flex w-full justify-center items-center rounded-md bg-osavus-blue-50 px-3 py-2 text-sm font-normal leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-50">
                <div i18n>Log in</div>
                <div role="status" class="mx-2" *ngIf="isSubmitting">
                  <svg aria-hidden="true" class="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-900"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor" />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill" />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </form>
        </div>

        <div class="relative flex mt-4 text-sm font-semibold">
          Don't have an account?
          <a routerLink="/login-portal/signup"
            class="ml-1 font-semibold text-sm cursor-pointer text-osavus-blue-50 hover:text-blue-500">Create an account</a>
        </div>
      </div>
    </div>
  </div>
  <app-toast class="absolute top-6 right-6" *ngIf="isToasting" [type]="toast_type" [message]="toast_message"
    (closing)="endMyToast()"></app-toast>
</div>