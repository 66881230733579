<div class="flex item-container">
    <span *ngIf="!link" class="font-semibold">{{ value }}</span>
    <span *ngIf="link" class="hover:text-osavus-blue-50 text-ellipsis overflow-hidden w-40 whitespace-nowrap font-semibold cursor-pointer" (click)="openLink(link)">{{ value }}</span>
    <img 
      class="pl-1 icon-copy"
      src="assets/eye.svg"      
      *ngIf="link"  
      (click)="openLink(link)"/>
    <img
      class="pl-1 icon-copy"
      src="assets/copy-outline.svg"
      title="Copy"
      [cdkCopyToClipboard]="value"
      (cdkCopyToClipboardCopied)="onCopy(tooltip)"
    />
    <span
      class="ml-2"
      #tooltip="matTooltip"
      matTooltip="Copied!"
      matTooltipPosition="right"
      matTooltipClass="mat-tooltip-success"
    ></span>
  </div>