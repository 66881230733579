<div i18n class="flex text-sm font-semibold leading-6 text-gray-900 pb-2">
  {{ config.label }}
  <div class="flex items-center justify-center w-5 h-5 pt-1">
    <img src="assets/info.svg" alt="" />
  </div>
</div>
<div #dropArea class="relative w-full h-full pt-0">
  <div class="absolute top-6 mt-5 text-center flex flex-col w-full">
    <img src="assets/upload-icon-outline.svg" alt="upload_icon_outline" class="w-4 h-4 flex self-center" />
    <div class="font-semibold text-sm">
      {{ config.fileTypesLabel }}
    </div>
    <div class="underline text-sm text-blue-400 cursor-pointer" role="presentation" (click)="openUploadDialog()">
      or browse for files
    </div>
  </div>

  <ejs-uploader
    #templateupload
    id="templatefileupload"
    class="cv-drop-area"
    [multiple]="false"
    [autoUpload]="false"
    [dropArea]="dropArea"
    [allowedExtensions]="config.allowedExtensions"
    [maxFileSize]="config.maxFileSize"
    (selected)="onFileSelect($event)"
    (removing)="onFileRemove($event)"
  >
  </ejs-uploader>
</div>
