import { Component, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'item-copy',
  templateUrl: './item-copy.component.html',
  styleUrl: './item-copy.component.scss'
})
export class ItemCopyComponent {
  @Input() value!: string;
  @Input() link?: string;

  private readonly TOOLTIP_DURATION = 1500;

  public onCopy(tooltip: MatTooltip): void {
    tooltip.show();

    setTimeout(() => {
      tooltip.hide();
    }, this.TOOLTIP_DURATION);
  }

  public openLink(url: string): void {
    window.open(url, '_blank');
  }

}
