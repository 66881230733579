<main-box>
  <mat-sidenav-container class="example-container" (backdropClick)="close('backdrop')" [hasBackdrop]="false">
    <mat-sidenav #sidenav (keydown.escape)="close('escape')" disableClose position="end">
      <app-candidate [candidateFromJob]="candidateId" (closeEvent)="closeSidenav($event)"></app-candidate>
    </mat-sidenav>

    <mat-sidenav-content>
      <ng-container>
        <div [ngClass]="isJobData ? 'visible' : 'invisible'" class="relative flex flex-col h-full pt-4 bg-white">
          <div class="flex w-full gap-2 px-4">
            <ng-container *ngIf="isJobData">
              <div class="flex gap-4 w-full items-center">
                <div class="flex h-full">
                </div>
                <div class="flex-col items-center justify-start flex-1 w-full pb-4">
                  <div class="flex justify-start flex-col">
                    <div class="block pb-1 text-sm md:text-2xl font-semibold truncate ...">
                      {{ jobData.jobTitle }}
                    </div>
                    <div class="flex h-4">
                      <div class="flex text-sm font-semibold text-orange-500 border-r-2 border-orange-200 pr-3">
                        {{ jobData.companyName }}
                      </div>
                      <div class="flex text-sm font-semibold text-gray-400 border-r-2 border-orange-100 px-3">
                        {{ jobData.location }}
                      </div>
                      <div class="flex text-sm font-semibold text-gray-400 border-r-2 border-orange-100 px-3">
                        {{ getJobTypeLabel(jobData.jobType) }}
                      </div>
                      <div class="flex text-sm font-semibold text-gray-400 pl-3">
                        {{ jobData.salary }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex gap-2 justify-start items-start h-full">
                  <div class="flex w-full items-center justify-end">
                    <div class="flex" *ngIf="initialLoadStatus">
                      <svg class="w-5 h-5 flex items-center justify-center text-gray-400"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                      </svg>
                      <span class="font-semibold text-sm text-gray-400">Your changes have been saved</span>
                    </div>

                    <div class="flex" *ngIf="showSaving && !showSaved">
                      <img class="w-5 h-5 pt-1 pr-1" src="assets/hour-glass.svg" />
                      <span class="font-semibold text-sm">Saving...</span>
                    </div>

                    <div class="flex" *ngIf="showSaved">
                      <svg class="w-5 h-5 flex items-center justify-center text-teal-800"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                      </svg>
                      <span class="font-semibold text-sm text-teal-800">Saved</span>
                    </div>
                    <div class="flex items-center gap-2 pl-4">
                      <select [(ngModel)]="externalStatus" (ngModelChange)="onExternalStatusChange($event)" [ngClass]="{
                          'active-background': externalStatus === 'Active',
                          'inactive-background': externalStatus === 'Inactive'
                        }" class="font-semibold text-sm px-3 py-1 gap-2 rounded-lg w-40 h-8">
                        <option value="Inactive">Inactive</option>
                        <option value="Active">Active</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <mat-tab-group class="w-full">
            <mat-tab bodyClass="py-4">
              <ng-template mat-tab-label>
                <div class="flex gap-2 items-center">
                  <img src="assets/left-alignment.svg" /> <span>Details</span>
                </div>
              </ng-template>
              <div class="flex flex-col w-full h-full gap-2 px-4 md:flex-row overflow-hidden bg-white">
                <div class="flex flex-col flex-[70%] overflow-y-scroll h-full">
                  <form #targetForm [formGroup]="jobDataForm" ngNativeValidate class="p-4 space-y-4 bg-white"  autocomplete="off">
                    <div class="w-full">
                      <app-input id="jobTitle" name="jobTitle" label="Job Title:" i18n-label formControlName="jobTitle" 
                        placeholder="Write job title here ..." i18n-placeholder ngDefaultControl>
                      </app-input>
                      <app-regenerate section="Title" [isIcon]="true" (regenerate)="regenerate($event)"
                        (onClear)="onClear('jobTitle')"></app-regenerate>
                    </div>

                    <div class="flex flex-col justify-between gap-3 lg:flex-row">
                      <div class="flex flex-row w-full gap-3 items-start">
                        <div class="w-1/3">
                          <app-input id="location" name="location" label="Location:" i18n-label
                            formControlName="location" placeholder="Write location here ..." i18n-placeholder
                            ngDefaultControl>
                          </app-input>
                        </div>
                        <div class="w-1/3">
                          <app-select id="jobType" name="jobType" label="Job type:" i18n-label formControlName="jobType"
                            [options]="jobTypes" [selected]="jobDataForm.value.jobType" ngDefaultControl>
                          </app-select>
                        </div>
                        <div class="w-1/3">
                          <app-input class="w-full" name="salary" label="Salary:" i18n-label formControlName="salary"
                            placeholder="$64-$84k USD" i18n-placeholder ngDefaultControl>
                          </app-input>
                        </div>
                      </div>
                    </div>

                    <div class="w-full">
                      <app-textarea id="jobSummary" name="jobSummary" autocomplete="jobSummary" label="Job Description:" autocomplete="off"
                        i18n-label i18n-placeholder placeholder="I need a senior software engineer ..."
                        formControlName="jobSummary" [rows]="6" ngDefaultControl>
                      </app-textarea>
                      <app-regenerate section="Summary" [isIcon]="true" (regenerate)="regenerate($event)"
                        (onClear)="onClear('jobSummary')"></app-regenerate>
                    </div>

                    <div class="w-full">
                      <div formArrayName="jobResponsibilities">
                        <label class="text-sm font-semibold pb-1">Responsibilities:</label>
                        <div class="relative pb-2" *ngFor="
                            let responsibility of jobResponsibilities.controls;
                            let i = index
                          " [formGroupName]="i">
                          <input #responsibilityInput id="jobResponsibilities" name="jobResponsibilities" autocomplete="off"
                            label="Responsibilities:" i18n-label formControlName="responsibility" placeholder="Add"
                            i18n-placeholder ngDefaultControl
                            class="block w-full h-9 rounded-md border-0 pr-10 shadow-sm ring-1 text-osavus-content-primary font-semibold ring-inset ring-gray-200 bg-gray-50 placeholder:text-gray-400 placeholder:font-normal focus:ring-2 focus:ring-inset text-sm" />
                          <div class="absolute inset-y-0 right-0 flex items-center w-8 pr-2 cursor-pointer"
                            (click)="removeResponsibility(i)">
                            <i class="w-6 h-6 text-gray-400 icon-close-white font-bold"></i>
                          </div>
                        </div>
                      </div>

                      <div class="relative w-full" (click)="addResponsibility()">
                        <input i18n-label placeholder="Add" i18n-placeholder autocomplete="off"
                          class="block cursor-pointer w-full h-9 rounded-md border-0 pr-10 shadow-sm ring-1 text-osavus-content-primary font-semibold ring-inset ring-gray-200 bg-gray-50 placeholder:text-osavus-blue-50 placeholder:font-semibold focus:ring-2 focus:ring-inset text-sm" />
                        <div class="absolute inset-y-0 right-0 flex items-center w-8 pr-2">
                          <i class="w-6 h-4 text-gray-400 icon icon-plus"></i>
                        </div>
                      </div>

                      <app-regenerate section="Responsibilities" [isIcon]="true" (regenerate)="regenerate($event)"
                        (onClear)="onClear('jobResponsibilities')"></app-regenerate>
                    </div>

                    <div class="w-full">
                      <div formArrayName="benefitsAndCompensation">
                        <label class="text-sm font-semibold pb-1">Benefits and Compensation:</label>
                        <div class="relative pb-2" *ngFor="
                            let benefit of benefitsAndCompensation.controls;
                            let j = index
                          " [formGroupName]="j">
                          <input #benefitInput id="benefit-{{ j }}" name="benefitsAndCompensation" autocomplete="off"
                            label="Benefits and Compensation:" i18n-label formControlName="benefit" placeholder="Add"
                            i18n-placeholder ngDefaultControl
                            class="block w-full h-9 rounded-md border-0 pr-10 shadow-sm ring-1 text-osavus-content-primary font-semibold ring-inset ring-gray-200 bg-gray-50 placeholder:text-gray-400 placeholder:font-normal focus:ring-2 focus:ring-inset text-sm" />
                          <div class="absolute inset-y-0 right-0 flex items-center w-8 pr-2 cursor-pointer"
                            (click)="removeBenefit(j)">
                            <i class="w-6 h-6 text-gray-400 icon-close-white font-bold"></i>
                          </div>
                        </div>
                      </div>

                      <div class="relative w-full" (click)="addBenefit()">
                        <input i18n-label placeholder="Add" i18n-placeholder autocomplete="off"
                          class="block cursor-pointer w-full h-9 rounded-md border-0 pr-10 shadow-sm ring-1 text-osavus-content-primary font-semibold ring-inset ring-gray-200 bg-gray-50 placeholder:text-osavus-blue-50 placeholder:font-semibold focus:ring-2 focus:ring-inset text-sm" />
                        <div class="absolute inset-y-0 right-0 flex items-center w-8 pr-2">
                          <i class="w-6 h-4 text-gray-400 icon icon-plus"></i>
                        </div>
                      </div>

                      <app-regenerate section="BenefitsAndCompensation" [isIcon]="true"
                        (regenerate)="regenerate($event)"
                        (onClear)="onClear('benefitsAndCompensation')"></app-regenerate>
                    </div>

                    <div class="w-full">
                      <div formArrayName="applicationProcess">
                        <label class="text-sm font-semibold pb-1">Application Process:</label>
                        <div class="relative pb-2" *ngFor="
                            let step of applicationProcess.controls;
                            let k = index
                          " [formGroupName]="k">
                          <input #applicationProcessInput id="application-process-{{ k }}" name="applicationProcess"  autocomplete="off"
                            label="Application Process:" i18n-label formControlName="step" placeholder="Add"
                            i18n-placeholder ngDefaultControl
                            class="block w-full h-9 rounded-md border-0 pr-10 shadow-sm ring-1 text-osavus-content-primary font-semibold ring-inset ring-gray-200 bg-gray-50 placeholder:text-gray-400 placeholder:font-normal focus:ring-2 focus:ring-inset font-normal text-sm" />
                          <div class="absolute inset-y-0 right-0 flex items-center w-8 pr-2 cursor-pointer"
                            (click)="removeApplicationStep(k)">
                            <i class="w-6 h-6 text-gray-400 icon-close-white font-bold"></i>
                          </div>
                        </div>
                      </div>

                      <div class="relative w-full" (click)="addApplicationStep()">
                        <input i18n-label placeholder="Add" i18n-placeholder autocomplete="off"
                          class="block cursor-pointer w-full h-9 rounded-md border-0 pr-10 shadow-sm ring-1 text-osavus-content-primary font-semibold ring-inset ring-gray-200 bg-gray-50 placeholder:text-osavus-blue-50 placeholder:font-semibold focus:ring-2 focus:ring-inset font-normal text-sm" />
                        <div class="absolute inset-y-0 right-0 flex items-center w-8 pr-2">
                          <i class="w-6 h-4 text-gray-400 icon icon-plus"></i>
                        </div>
                      </div>

                      <app-regenerate section="applicationProcess" [isIcon]="true" (regenerate)="regenerate($event)"
                        (onClear)="onClear('applicationProcess')"></app-regenerate>
                    </div>

                    <div class="w-full">
                      <app-multi-select id="languages" name="languages" label="Languages:" i18n-label i18n-placeholder autocomplete="off"
                        placeholder="Choose languages here ..." [options]="languageList" formControlName="languages"
                        ngDefaultControl>
                      </app-multi-select>
                    </div>

                    <div class="flex w-full py-2 border-b border-gray-300">
                      <app-label title="Company Details"></app-label>
                    </div>

                    <div class="flex flex-row justify-between gap-3">
                      <div class="w-1/2">
                        <app-input id="companyName" name="companyName" label="Name" i18n-label
                          formControlName="companyName" placeholder="Write company name here ..." i18n-placeholder
                          ngDefaultControl>
                        </app-input>
                      </div>
                      <div class="w-1/2">
                        <app-input id="companyContact" name="companyContact" label="Contact" i18n-label
                          formControlName="companyContact" placeholder="Write company contact here ..." i18n-placeholder
                          ngDefaultControl>
                        </app-input>
                      </div>
                    </div>
                    <div class="flex flex-row justify-between gap-3">
                      <div class="w-1/2">
                        <app-input id="companyWebsite" name="companyWebsite" label="Website" i18n-label
                          formControlName="companyWebsite" placeholder="Write company website here ..." i18n-placeholder
                          ngDefaultControl>
                        </app-input>
                      </div>
                      <div class="w-1/2">
                        <app-input id="companyEmail" name="companyEmail" label="Email" i18n-label
                          formControlName="companyEmail" placeholder="Write company email here ..." i18n-placeholder
                          ngDefaultControl>
                        </app-input>
                      </div>
                    </div>
                    <div class="w-full">
                      <app-textarea id="companyDescription" name="companyDescription" autocomplete="companyDescription"
                        label="Description:" i18n-label i18n-placeholder
                        placeholder="Write company description here ..." formControlName="companyDescription" [rows]="6"
                        ngDefaultControl>
                      </app-textarea>
                    </div>

                    <div class="w-full">
                      <app-input id="contactInformation" name="contactInformation" label="Contact information"
                        i18n-label formControlName="contactInformation" placeholder="Write Contact information here ..."
                        i18n-placeholder ngDefaultControl>
                      </app-input>
                    </div>
                  </form>
                </div>
                <div class="flex flex-col flex-[30%] overflow-y-scroll">
                </div>
              </div>
            </mat-tab>
            <mat-tab bodyClass="py-4">
              <ng-template mat-tab-label>
                <div class="flex gap-2 items-center">
                  <img src="assets/tuning-icon.svg" /> <span>Criteria</span>
                </div>
              </ng-template>
              <app-criteria (initialLoadStatusChanged)="onInitialLoadStatusChanged($event)"
                (showSavingChanged)="onShowSavingChanged($event)" (showSavedChanged)="onShowSavedChanged($event)">
              </app-criteria>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div class="flex gap-2 items-center">
                  <img src="assets/users.svg" /> <span>Candidates</span>
                </div>
              </ng-template>
              <app-job-candidates (openSidenav)="onItemSelect($event)"></app-job-candidates>
            </mat-tab>
            <mat-tab bodyClass="py-4">
              <ng-template mat-tab-label>
                <div class="flex gap-2 items-center">
                  <span>Track</span>
                </div>
              </ng-template>
              <app-track-candidates [jobId]="jobId"></app-track-candidates>
            </mat-tab>
          </mat-tab-group>

        </div>
      </ng-container>
      <app-loading *ngIf="isLoadingJob && !isJobData"></app-loading>
      <job-preview *ngIf="showPreview" [jobData]="jobData" (onCLose)="showPreview = false"></job-preview>
    </mat-sidenav-content>
  </mat-sidenav-container>
</main-box>
<app-toast class="fixed z-50 top-6 right-6" *ngIf="isGenerateToasting" [type]="toast_type" [message]="toast_message"
  (closing)="hideToast()"></app-toast>