import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, take } from 'rxjs';
import { ISievePayload } from 'src/app/shared/models/sieve-payload.interface';
import { environment } from 'src/environments/environment.staging';

@Injectable()
export class CRUDService {

  constructor(private _httpClient: HttpClient) {}

  public getList(endpoint: string, payload: ISievePayload): Observable<any> {
    return this._httpClient
      .get<any>(`${environment.link}/${endpoint}`, {
        responseType: 'json',
        observe: 'response',
        params: {
          ...payload,
        },
      })
      .pipe(take(1));
  }

  public delete(endpoint: string, id: string): Observable<any> {
    return this._httpClient
      .delete<any>(`${environment.link}/${endpoint}/${id}`, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  public batchDelete(endpoint: string, ids: string[]): Observable<any> {
    const options = {
      body: { ids },
      responseType: 'json' as const,
      observe: 'response' as const,
    };
    return this._httpClient
      .delete<any>(`${environment.link}/${endpoint}/batch-delete`, options)
      .pipe(take(1));
  }
}
