import { MatDialog } from '@angular/material/dialog';
import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalUploadModalComponent } from 'src/app/components/global-upload-modal/global-upload-modal.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Component({
  selector: 'app-candidates-upload-modal',
  templateUrl: './candidates-upload-modal.component.html',
  styleUrl: './candidates-upload-modal.component.scss',
})
export class CandidatesUploadModalComponent {
  @Input() label: string = 'Button';
  @Input() icon: string = '';
  @Input() styleType: 'primary' | 'secondary' = 'primary';

  constructor(
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {}

  openCandidateUploadModal() {
    const isMobile = this.breakpointObserver.isMatched('(max-width: 599px)');
    const dialogRef = this.dialog.open(GlobalUploadModalComponent, {
      height: isMobile ? '90%' : 'auto',
      maxHeight: isMobile ? '90%' : '690px',
      width: isMobile ? '90%' : '750px',
      data: {
        uploadUrl: `${environment.link}/cv/upload`,
        modalTitle: 'Upload Candidate File',
        modalSubText: 'Upload a candidate-related file.',
        allowedExtensions: '.pdf,.doc,.docx',
        maxFileSize: 10485760,
        modalType: 'candidate'
      },
      disableClose: true,
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigate(['hiring-portal/jobs/create', result]);
      }
    });
  }
}
