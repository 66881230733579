import { NgClass } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { CandidatesService } from 'src/app/modules/hiring-portal/components/candidates/candidates.service';
import { JobsService } from 'src/app/modules/hiring-portal/modules/jobs/services/jobs.service';
import { FileService } from 'src/app/shared/helper-services/file.service';

@Component({
  selector: 'app-download-pdf',
  templateUrl: './download-pdf.component.html',
  styleUrl: './download-pdf.component.scss',
})
export class DownloadPdfComponent implements OnInit {
  @Input() id!: string;
  @Input() type!: string;
  @Input() title!: string;
  @Input('ngClass') styles: NgClass['ngClass'];

  constructor(
    private candidatesService: CandidatesService,
    private jobService: JobsService,
    private fileService: FileService,
  ) {}

  ngOnInit(): void {}

  private getFilename(response: HttpResponse<Blob>): string | undefined {
    return response?.headers?.get('X-Filename')?.slice(1, -1);
  }

  private getContentType(response: HttpResponse<Blob>): string {
    return response?.headers?.get('Content-Type') ?? '';
  }

  public downloadPdf(id: string): void {
    if (this.type === 'cv') {
      this.candidatesService
        .getCandidateFile(id)
        .subscribe((response: HttpResponse<Blob>) => {
          const filename = this.getFilename(response);
          const contentType = this.getContentType(response);
          this.fileService.downloadFile(response.body!, contentType, filename ?? id);
        });
    } else if (this.type === 'job') {
      this.jobService
        .getJobFile(id)
        .subscribe((response: HttpResponse<Blob>) => {
          const filename = this.getFilename(response);
          const contentType = this.getContentType(response);
          this.fileService.downloadFile(response.body!, contentType, filename ?? id);
        });
    }
  }
}
