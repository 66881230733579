import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
})
export class SearchComponent implements OnDestroy {
  @Input() searchTerm!: string;
  @Output() searchItem = new EventEmitter();

  public searchSubject = new Subject<string>();
  private readonly SEARCH_DELAY = 600;

  constructor() {
    this.searchSubject.pipe(debounceTime(this.SEARCH_DELAY), distinctUntilChanged()).subscribe(() => {
      this.searchItem.emit(this.searchTerm);
    });
  }

  ngOnDestroy(): void {
    this.searchSubject.unsubscribe();
  }

  public onSearch() {
    this.searchSubject.next(this.searchTerm);
  }
}
