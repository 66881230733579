<div class="container-settings">
	<div class="sidebar">
	  <h1>Settings</h1>
  	  <button
		(click)="selectedIndex = 0"
		[ngClass]="{'active': selectedIndex === 0}"
		class="tab-button">
		<h4>Profile</h4>
		<p>Edit your account information</p>
	  </button>
  	  <button class="tab-button disabled" disabled>
		<h4>Billing</h4>
		<p>Transactions, payment details</p>
	  </button>
  	  <button class="tab-button disabled" disabled>
		<h4>Integrations</h4>
		<p>Transactions, payment details</p>
	  </button>
	</div>
  	<div class="content">
	  <div *ngIf="selectedIndex === 0">
		<app-profile-settings></app-profile-settings>
	  </div>
	</div>
</div>