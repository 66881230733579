import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  CandidatesExternalStatus,
} from './candidates.service';
import { IMatColumn } from 'src/app/components/table/models/mat-column.interface';
import { ITableConfig } from 'src/app/components/table/models/table-config.model';
import { ISievePayload } from 'src/app/shared/models/sieve-payload.interface';
import { IListCount } from 'src/app/components/table/models/list-count.interface';

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss'],
})
export class CandidatesComponent implements OnInit {
  public isLoading: boolean = true;
  public hasInitialData: boolean = true;

  public columns: IMatColumn[] = [
    {
      field: 'fullName',
      header: 'CANDIDATE NAME',
      sortable: true,
      searchKey: 'fullname',
    },
    { field: 'email', header: 'EMAIL', sortable: true, searchKey: 'email' },
    {
      field: 'location',
      header: 'LOCATION',
      sortable: true,
      searchKey: 'location',
    },
    {
      field: 'activeJobTitle',
      header: 'CURRENT ROLE',
      sortable: true,
      searchKey: 'activejobtitle',
    },
    { field: 'createdAt', header: 'DATE ADDED', sortable: true },
  ];

  public tableConfig: ITableConfig = {
    defaultPayload: {
      page: 1,
      pageSize: 25,
      sorts: '-createdAt',
    } as ISievePayload,
  };

  constructor(
    public dialog: MatDialog,
    private _router: Router,
  ) {}

  ngOnInit(): void {}

  public redirectToPage(id: string): void {
    this._router.navigate(['hiring-portal/candidates/', id]);
  }

  public get Status() {
    return CandidatesExternalStatus;
  }

  public openLink(url: string): void {
    window.open(url, '_blank');
  }

  public onListCountUpdated(listCount: IListCount): void {
    this.hasInitialData = listCount.initialCount > 0;
    this.isLoading = false;
  }
}
