import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { IMatColumn } from 'src/app/components/table/models/mat-column.interface';
import { ITableConfig } from 'src/app/components/table/models/table-config.model';
import { ISievePayload } from 'src/app/shared/models/sieve-payload.interface';
import { IListCount } from 'src/app/components/table/models/list-count.interface';

@Component({
  selector: 'app-jobs-list',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.scss'],
})
export class JobsListComponent implements OnInit {
  public isLoading: boolean = true;
  public hasInitialData: boolean = true;

  public columns: IMatColumn[] = [
    {
      field: 'jobTitle',
      header: 'JOB POSITION',
      sortable: true,
      searchKey: 'jobtitle',
    },
    {
      field: 'appliedCount',
      header: 'APPLIED',
      sortable: false,
    },
    {
      field: 'jobLink',
      header: 'JOB LINK',
      sortable: false,
    },
    {
      field: 'externalStatus',
      header: 'JOB STATUS',
      sortable: false,
    },
    {
      field: 'createdByName',
      header: 'CREATED BY',
      sortable: true,
    },
    {
      field: 'createdAt',
      header: 'JOB CREATED',
      sortable: true,
    },
  ];


  public tableConfig: ITableConfig = {
    defaultPayload: {
      page: 1,
      pageSize: 25,
      sorts: '-createdAt',
    } as ISievePayload,
  };

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit(): void { }

  redirectToJobPage(jobId: string): void {
    this.router.navigate(['hiring-portal/jobs/job', jobId]);
  }

  public onListCountUpdated(listCount: IListCount): void {
    this.hasInitialData = listCount.initialCount > 0;
    this.isLoading = false;
  }
  
}
