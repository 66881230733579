import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { BreakpointObserver } from '@angular/cdk/layout';
import { GlobalUploadModalComponent } from 'src/app/components/global-upload-modal/global-upload-modal.component';

@Component({
  selector: 'app-create-job-modal',
  templateUrl: './create-job-modal.component.html',
  styleUrl: './create-job-modal.component.scss'
})
export class CreateJobModalComponent {
  @Input() label: string = 'Create Job';
  @Input() icon: string = '';
  @Input() styleType: 'primary' | 'secondary' = 'primary';
  
  constructor(
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {}

  openCreateJobAdModal() {
    const isMobile = this.breakpointObserver.isMatched('(max-width: 599px)');
    const dialogRef = this.dialog.open(GlobalUploadModalComponent, {
      height: isMobile ? '90%' : 'auto',
      maxHeight: isMobile ? '90%' : '690px',
      width: isMobile ? '90%' : '750px',
      data: {
        uploadUrl: `${environment.link}/job/upload`,
        modalTitle: 'Upload Job File',
        modalSubText: 'Upload a job-related file.',
        allowedExtensions: '.pdf,.doc,.docx',
        maxFileSize: 10485760,
        modalType: 'job'
      },
      disableClose: true,
    });
  
    dialogRef.afterClosed().subscribe(() => {
      dialogRef.componentRef?.destroy();
    });
  }
}
