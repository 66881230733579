<main-box>
  <app-loading *ngIf="isLoading"></app-loading>
  <div
    class="flex flex-col w-full h-full gap-5 p-4"
    [ngClass]="hasInitialData ? 'bg-gray-100' : 'bg-white'">
    <div class="p-4">
      <p class="text-2xl font-semibold text-osavus-black m-0">Candidates</p>
    </div>
    <ng-container *ngIf="hasInitialData; else emptyList">
      <div class="w-full h-full overflow-hidden">
        <app-table
          [columns]="columns"
          [endPoint]="'organization/candidates'"
          [tableConfig]="tableConfig"
          (listCountUpdated)="onListCountUpdated($event)">
          <ng-template tableCustomCell="fullName" let-item>
            <item-initials-and-link
              [initials]="item.initials"
              [value]="item.fullName"
              (clicked)="redirectToPage(item.id)"
            />
          </ng-template>
          <ng-template tableCustomCell="email" let-item>
            <item-copy [value]="item.email" />
          </ng-template>
          <ng-template tableCustomCell="createdAt" let-item>
            <span class="text-gray-500 font-semibold">{{
              item.createdAt | date: "MMM d y "
            }}</span>
          </ng-template>
          <ng-template #rowActions let-data>
            <app-download-pdf
              [ngClass]="'mr-3 actions-icon-button'"
              [id]="data.id"
              [type]="'cv'"
              [title]="'Download original CV'"
            ></app-download-pdf>
          </ng-template>
          <ng-template #rowActionsMenu let-data>
            <app-open-pdf [id]="data.id"></app-open-pdf>
          </ng-template>
          <ng-template #tableActions>
            <app-candidates-upload-modal label="New candidate" icon="icon-plus" styleType="primary"></app-candidates-upload-modal>
          </ng-template>
        </app-table>
      </div>
    </ng-container>

    <ng-template #emptyList>
      <ng-container *ngIf="!isLoading">
        <div class="flex flex-col items-center justify-center w-full h-full">
          <img
            src="assets/woman_collects_stars_in_the_sky.svg"
            class="h-2/4"
            alt="add_candidate_image"
          />
          <p class="my-8 add-candidates-text">Add your first candidate</p>
          <app-candidates-upload-modal label="New candidate" icon="icon-plus" styleType="primary"></app-candidates-upload-modal>
        </div>
      </ng-container>
    </ng-template>
  </div>
</main-box>
