<main-box>
    <div class="p-8 bg-gray-50 min-h-screen flex justify-center">
        <div class="max-w-4xl w-full">
            <h1 class="text-[32px] font-bold mb-8">Welcome back, {{ overviewData?.name }}.</h1>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-6 pb-6">
                <div class="p-6 bg-white rounded-lg shadow-lg">
                    <div class="flex justify-between">
                        <h2 class="text-[22px] font-semibold m-0">Total Jobs</h2>
                        <i class="text-xl text-black shrink-0 flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-7"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" /></svg>
                        </i> 
                    </div>
                    <h2 class="text-[32px] font-semibold m-0 pt-3">{{ overviewData?.totalJobs}}</h2>
                </div>

                <div class="p-6 bg-white rounded-lg shadow-lg">
                    <div class="flex justify-between">
                        <h2 class="text-[22px] font-semibold m-0">Total Candidates</h2>
                        <i class="text-xl text-black shrink-0 icon-users flex items-center justify-center"></i>
                    </div>
                    <h2 class="text-[32px] font-semibold m-0 pt-3">{{ overviewData?.totalCandidates}}</h2>
                </div>
            </div>
            
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div class="p-6 bg-white rounded-lg shadow-lg">
                    <h2 class="text-[22px] font-semibold m-0">Create a job</h2>
                    <p class="text-gray-500 mb-4 text-base m-0">Start recruiting by creating a new job listing.</p>
                    <div class="flex justify-end space-x-2 pt-12">
                        <app-primary-button [routerLink]="['/hiring-portal/jobs']" label="View jobs">View all jobs</app-primary-button>
                        <app-create-job-modal label="Upload existing job ad" styleType="secondary"></app-create-job-modal>
                    </div>
                </div>
                <div class="p-6 bg-white rounded-lg shadow-lg">
                    <h2 class="text-[22px] font-semibold m-0">Manage your candidates</h2>
                    <p class="text-gray-500 mb-4 text-base m-0">Keep track of all your candidates.</p>
                    <div class="flex justify-end space-x-2 pt-12">
                        <app-primary-button [routerLink]="['/hiring-portal/candidates']" label="View all candidates">View all candidates</app-primary-button>
                        <app-candidates-upload-modal label="Upload CV" styleType="secondary"></app-candidates-upload-modal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main-box>