import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';

import { ButtonComponent } from './button/button.component';
import { InputComponent } from './input/input.component';
import { LabelComponent } from './label/label.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { SelectComponent } from './select/select.component';
import { TextareaComponent } from './textarea/textarea.component';
import { ToastComponent } from './toast/toast.component';
import { UploadComponent } from './upload/upload.component';
import { PrimaryButtonComponent } from './button/primary-button/primary-button.component';
import { DropdownButtonComponent } from './button/dropdown-button/dropdown-button.component';
import { OutlineButtonComponent } from './button/outline-button/outline-button.component';
import { ItemInitialsAndLinkComponent } from './table-elements/item-initials-and-link/item-initials-and-link.component';
import { MaterialModule } from '../material.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ItemCopyComponent } from './table-elements/item-copy/item-copy.component';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';

const CORE_UI_COMPONENTS = [
  ButtonComponent,
  InputComponent,
  LabelComponent,
  MultiSelectComponent,
  SelectComponent,
  TextareaComponent,
  ToastComponent,
  UploadComponent,
  PrimaryButtonComponent,
  DropdownButtonComponent,
  OutlineButtonComponent,
  ItemInitialsAndLinkComponent,
  ItemCopyComponent,
];
@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    MaterialModule,
    ClipboardModule,
    UploaderModule
  ],
  declarations: [CORE_UI_COMPONENTS],
  exports: [CORE_UI_COMPONENTS],
})
export class CoreUIModule {}
