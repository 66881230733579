import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class TextareaComponent {
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() autocomplete: string = '';
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() rows: number = 4;
  @Input() formControlName: string = '';

  constructor(public controlContainer: FormGroupDirective) {}

  get formControl() {
    return this.controlContainer.form.get(this.formControlName);
  }

  get hasError() {
    return this.formControl && this.formControl.invalid && this.formControl.touched;
  }

  get errorMessage() {
    if (this.formControl?.errors?.['required']) {
      return 'Field is required';
    }
    return 'Invalid input';
  }
}
