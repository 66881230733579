import { ISievePayload } from 'src/app/shared/models/sieve-payload.interface';
import { IBulkActionItem } from './bulk-action-item.interface';

export class TableConfig implements ITableConfig {
  public enableSelection: boolean = true;
  public enableRowActions: boolean = true;
  public enableSearch: boolean = true;
  public defaultPayload: ISievePayload = {
    page: 1,
    pageSize: 25,
  } as ISievePayload;
  public customBulkActions: IBulkActionItem[] = [];
  public bulkDeletePositionIndex: number = 0;
  public pageSizeOptions: number[] = [5, 10, 25, 100];

  constructor(config: Partial<ITableConfig> = {}) {
    Object.assign(this, config);
  }
}

export interface ITableConfig {
  enableSelection?: boolean;
  enableRowActions?: boolean;
  enableSearch?: boolean;
  defaultPayload?: ISievePayload;
  customBulkActions?: IBulkActionItem[];
  bulkDeletePositionIndex?: number;
  pageSizeOptions?: number[];
}
