import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RemovingEventArgs } from '@syncfusion/ej2-angular-inputs';
import { IFileUploadConfig } from '../models/file-upload-config';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  @Input() config!: IFileUploadConfig;
  @Output() onChange = new EventEmitter();
  @Output() onRemove = new EventEmitter();

  private _uploadButton: HTMLButtonElement | undefined;

  constructor(
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this._uploadButton = this.elementRef.nativeElement.querySelector('.e-css.e-btn');
  }

  public openUploadDialog(): void {
    this._uploadButton?.click();
  }

  public onFileSelect(args: any): void {
    const selectedFile = args.filesData[0].rawFile;

    if (!this.config.allowedFileTypes.includes(selectedFile.type) || selectedFile.size > this.config.maxFileSize) {
      this.onChange.emit(null);
    } else {
      this.onChange.emit(new Blob([selectedFile]));
    }
  }

  public onFileRemove(args: RemovingEventArgs): void {
    args.postRawFile = false;
    this.onRemove.emit();
  }
}
