<main-box>
  <app-loading *ngIf="isLoading"></app-loading>
  <div class="flex flex-col w-full h-full gap-5 p-5 bg-gray-100" [ngClass]="hasInitialData ? 'bg-gray-100' : 'bg-white'">
    
    <ng-container *ngIf="hasInitialData; else emptyList">
      <div class="w-full h-full overflow-hidden">
        <app-table 
          [columns]="columns" 
          [endPoint]="candidateEndpoint" 
          [tableConfig]="tableConfig"
          (listCountUpdated)="onListCountUpdated($event)">

          <ng-template tableCustomCell="fullName" let-item>
            <item-initials-and-link
              [initials]="item.initials"
              [value]="item.fullName"
              (click)="onItemSelect(item.id)"/>
          </ng-template>

          <ng-template tableCustomCell="email" let-item>
            <item-copy [value]="item.email" />
          </ng-template>

          <ng-template tableCustomCell="location" let-item>
            <span class="text-gray-500 font-semibold custom-width" >{{ item.location }}</span>
          </ng-template>

          <ng-template tableCustomCell="jobMatchScore" let-item>
            <span class="inline-block h-8 w-8 rounded-full bg-green-100 text-xs font-semibold text-center leading-8">{{ item.jobMatchScore }}</span>
          </ng-template>

          <ng-template tableCustomCell="createdAt" let-item>
            <!-- TODO: move datTime pipe in table componenet and in future try to make the pipe to handel different type of values -->
            <span class="text-gray-500 font-semibold">{{ item.createdAt | date: "MMM d y " }}</span>
          </ng-template>

          <ng-template #rowActions let-data>
            <app-download-pdf
              [ngClass]="'mr-3 actions-icon-button'"
              [id]="data.orgCandidateId"
              [type]="'cv'"
              [title]="'Download original CV'"
            ></app-download-pdf>
          </ng-template>

        </app-table>
      </div>
    </ng-container>

    <ng-template #emptyList>
      <div *ngIf="!isLoading" class="flex flex-col items-center w-full gap-3"></div>
    </ng-template>
  </div>
</main-box>