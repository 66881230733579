import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MarkedDirective } from './marked/marked.directive';
import { TableCustomCellDirective } from './table-custom-cell/table-custom-cell.directive';

const DIRECTIVES = [MarkedDirective, TableCustomCellDirective];

@NgModule({
  imports: [CommonModule],
  declarations: [DIRECTIVES],
  exports: [DIRECTIVES],
})
export class DirectivesModule {}
