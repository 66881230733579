<app-primary-button
  *ngIf="styleType === 'primary'"
  [label]="label"
  [icon]="icon"
  (click)="openCreateJobAdModal()"
></app-primary-button>
<button
  *ngIf="styleType === 'secondary'"
  (click)="openCreateJobAdModal()"
  class="px-4 py-3 text-sm font-semibold bg-blue-100 text-blue-500 rounded-lg"
>{{ label }}</button>