import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrl: './profile-settings.component.scss'
})
export class ProfileSettingsComponent {
  jobDataForm: FormGroup;


  constructor(
    private formBuilder: FormBuilder,
  ){
    this.jobDataForm = this.formBuilder.group({
      name: [''],
      email: [''],
      // password: [''],
    });
  }
}
