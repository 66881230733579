import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { CRUDService } from 'src/app/services/crud/crud.service';
import { TableCustomCellDirective } from '../../directives/table-custom-cell/table-custom-cell.directive';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { IMatColumn } from './models/mat-column.interface';
import { IBulkActionItem } from './models/bulk-action-item.interface';
import { IListCount } from './models/list-count.interface';
import { ITableConfig, TableConfig } from './models/table-config.model';
import { ISievePayload } from 'src/app/shared/models/sieve-payload.interface';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
  providers: [CRUDService],
})
export class TableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() columns!: IMatColumn[];
  @Input() endPoint!: string;
  @Input() set tableConfig(config: ITableConfig) {
    this._tableConfig = new TableConfig(config);
  }
  @Input() dataRefreshTrigger: boolean = false;

  @Output() bulkActionClicked = new EventEmitter<IBulkActionItem>();
  @Output() listCountUpdated = new EventEmitter<IListCount>();

  @ViewChild(ConfirmationModalComponent)
  confirmationModal!: ConfirmationModalComponent;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('searchInput') searchInput!: ElementRef;

  @ContentChildren(TableCustomCellDirective)
  customCellTemplates!: QueryList<TableCustomCellDirective>;
  @ContentChild('rowActions', { static: true })
  rowActionsTemplate!: TemplateRef<any>;
  @ContentChild('rowActionsMenu', { static: true })
  rowActionsMenuTemplate!: TemplateRef<any>;
  @ContentChild('tableActions', { static: true })
  tableActionsTemplate!: TemplateRef<any>;

  private _tableConfig: TableConfig = new TableConfig();

  public get enableSelection(): boolean {
    return this._tableConfig.enableSelection;
  }

  public get enableRowActions(): boolean {
    return this._tableConfig.enableRowActions;
  }

  public get enableSearch(): boolean {
    return this._tableConfig.enableSearch;
  }

  public get defaultPayload(): ISievePayload {
    return this._tableConfig.defaultPayload;
  }

  public get customBulkActions(): IBulkActionItem[] {
    return this._tableConfig.customBulkActions;
  }

  public get bulkDeletePositionIndex(): number {
    return this._tableConfig.bulkDeletePositionIndex;
  }

  public get pageSizeOptions(): number[] {
    return this._tableConfig.pageSizeOptions;
  }

  public data: any[] = [];
  public selection = new SelectionModel<string>(true, []);
  public displayedColumns!: string[];

  public confirmationMessage: string | undefined;
  public isLoading: boolean = false;
  public _selectedId: string | undefined;
  public openDeleteConfirmationModal: boolean = false;

  public searchTerm: string = '';

  private _currentSortState: Sort = { active: '', direction: '' };
  public listCount: IListCount = { initialCount: 0, filteredCount: 0 };

  public bulkActions: IBulkActionItem[] = [];
  private readonly DEFAULT_BULK_ACTIONS: IBulkActionItem[] = [
    { label: 'Bulk delete', value: 'bulkDelete' } as IBulkActionItem,
  ];

  constructor(
    private _crudService: CRUDService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.bulkActions = this.createBulkActions();
    this.displayedColumns = this.createDisplayColumns();

    this.getList(this.defaultPayload, () => {
      this.listCount.initialCount = this.listCount.filteredCount;
      this.listCountUpdated.emit(this.listCount);
    });
  }

  ngAfterViewInit() {
    this.initializeSort();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['refreshTrigger'] && this.dataRefreshTrigger) {
      this.getList(this.createCurrentPayload(), () => {
        this.dataRefreshTrigger = false;
      });
    }
  }

  public isAllSelected() {
    return this.selection.selected.length === this.data.length;
  }

  public masterToggle() {
    this.isAllSelected() 
    ? this.selection.clear() 
    : this.data.forEach((row) => this.selection.select(row.id));
  }

  public getCustomTemplate(columnField: string): TemplateRef<any> | null {
    const customCell = this.customCellTemplates.find((cell) => cell.columnField === columnField);
    return customCell ? customCell.templateRef : null;
  }

  public onDelete(id: string): void {
    this._selectedId = id;
    this.confirmationMessage = 'Are you sure you want to delete this item?';
    this.openDeleteConfirmationModal = true;
  }

  public onBulkAction(action: IBulkActionItem) {
    if (action.value === 'bulkDelete') {
      this.confirmationMessage = 'Are you sure you want to delete selected items?';
      this.openDeleteConfirmationModal = true;
    } else {
      this.bulkActionClicked.emit(action);
    }
  }

  public onSort(event: Sort): void {
    this.paginator.pageIndex = 0;
    this.selection.clear();

    this._currentSortState = event;
    this.sort.active = event.active;
    this.sort.direction = event.direction;

    this.getList(this.createCurrentPayload());
  }

  public onPageChange(): void {
    this.selection.clear();
    this.getList(this.createCurrentPayload());
  }

  public handleDeleteResponse(deletedCount: number): void {
    if (deletedCount === this.listCount.filteredCount) {
      this.paginator.pageIndex = 0;
      this.searchTerm = '';
    } else if (deletedCount === this.data.length) {
      const totalPages = Math.max(this.paginator.getNumberOfPages() - 1, 0);

      if (this.paginator.pageIndex >= totalPages) {
        this.paginator.pageIndex = totalPages - 1;
      }
    }
    this.selection.clear();

    this.getList(this.createCurrentPayload(), () => {
      if (this.listCount.filteredCount === 0) {
        this.listCount.initialCount = this.listCount.filteredCount;
        this.listCountUpdated.emit(this.listCount);
      }
    });
  }

  private focusSearchInput(): void {
    if (this.searchInput && !this.isLoading) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 0);
    }
  }

  public handleSearch(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.paginator.pageIndex = 0;
    this.selection.clear();

    this.getList(this.createCurrentPayload(), () => {
      this.focusSearchInput();
    });
  }

  private createSearchFilter(): string {
    const searchFields = this.columns
      .filter((column) => column.searchKey)
      .map((x) => x.searchKey)
      .join('|');

    return `(${searchFields})@=*${this.searchTerm}`;
  }

  private getList(payload: ISievePayload, callback?: () => void) {
    this.isLoading = true;

    this._crudService.getList(this.endPoint, payload).subscribe({
      next: (result) => {
        if (result) {
          this.data = result.body;
          try {
            this.listCount.filteredCount = parseInt(result.headers.get('X-Totalcount') as string);
          } catch (e) {
            this.listCount.filteredCount = this.data.length;
          }
        }

        this.isLoading = false;

        if (callback) {
          callback();
        }
      },
      error: (e) => {
        console.error('Error fetching data', e);
        this.isLoading = false;
      },
    });
  }

  private createCurrentPayload(): ISievePayload {
    const sorts = this.createSorts(this._currentSortState);
    return {
      page: this.paginator.pageIndex + 1,
      pageSize: this.paginator.pageSize,
      ...(sorts ? { sorts: sorts } : undefined),
      ...(this.searchTerm.length > 0 
        ? { filters: this.createSearchFilter() } 
        : undefined),
    } as ISievePayload;
  }

  private createBulkActions(): IBulkActionItem[] {
    const bulkActions = [...this.customBulkActions];

    bulkActions.splice(
      this.bulkDeletePositionIndex, 
      0, 
      ...this.DEFAULT_BULK_ACTIONS);
    return bulkActions;
  }

  private createDisplayColumns(): string[] {
    const columnFields = this.columns.map((column) => column.field);

    if (this.enableSelection) {
      columnFields.unshift('select');
    }
    if (this.enableRowActions) {
      columnFields.push('actions');
    }

    return columnFields;
  }

  private createSorts(event: Sort): string | undefined {
    return event.active && event.direction 
    ? `${event.direction === 'asc' ? '' : '-'}${event.active}` 
    : undefined;
  }

  private initializeSort() {
    if (!this.defaultPayload.sorts) {
      return;
    }

    const sortDirection = this.defaultPayload.sorts.startsWith('-') 
    ? 'desc' 
    : 'asc';
    const sortField = this.defaultPayload.sorts.replace('-', '');

    this.sort.active = sortField;
    this.sort.direction = sortDirection as 'asc' | 'desc';

    this._cdr.detectChanges();

    this.sort.sortChange.emit({
      active: this.sort.active,
      direction: this.sort.direction,
    } as Sort);
  }
}
